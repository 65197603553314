// @ts-check
import { assert } from '@agoric/assert';
/**
 * Parse the string as a setValue
 *
 * @param {string} _str
 */

export const parseAsSet = _str => {
  assert.fail('parseAsSet not yet implemented');
};